import invariant from 'react-utils/invariant';
import { removeDomainFromTitleAudit } from '../recommendations/removeDomainFromTitle/removeDomainFromTitleAudit';
import { GathererKeys } from 'xray-recommendation-lib/gatherers/allGatherers';
import { addTitleAudit } from '../recommendations/addTitle/addTitleAudit';
import { shortenTitleAudit } from '../recommendations/shortenTitle/shortenTitleAudit';
import { ShortenTitleView } from '../recommendations/shortenTitle/ShortenTitleView';
import { RemoveDomainFromTitleView } from '../recommendations/removeDomainFromTitle/RemoveDomainFromTitleView';
import { AddTitleView } from '../recommendations/addTitle/AddTitleView';
import { addMetaDescriptionAudit } from '../recommendations/addMetaDescription/addMetaDescriptionAudit';
import { AddMetaDescriptionView } from '../recommendations/addMetaDescription/AddMetaDescriptionView';
import { removeTitleFromMetaDescriptionAudit } from '../recommendations/removeTitleFromMetaDescription/removeTitleFromMetaDescriptionAudit';
import { RemoveTitleFromMetaDescriptionView } from '../recommendations/removeTitleFromMetaDescription/RemoveTitleFromMetaDescriptionView';
import { shortenMetaDescriptionAudit } from '../recommendations/shortenMetaDescription/shortenMetaDescriptionAudit';
import { ShortenMetaDescriptionView } from '../recommendations/shortenMetaDescription/ShortenMetaDescriptionView';
import { SeoRecommendationGroups, SeoRecommendations } from '../constants';
import { addH1TagsAudit } from '../recommendations/addH1Tags/addH1TagsAudit';
import { verifyEmptyImageAltTextAudit } from '../recommendations/verifyEmptyImageAltText/verifyEmptyImageAltTextAudit';
import { VerifyEmptyImageAltTextView } from '../recommendations/verifyEmptyImageAltText/VerifyEmptyImageAltTextView';
import { addViewportTagAudit } from '../recommendations/addViewportTag/addViewportTagAudit';
import { AddViewportTagView } from '../recommendations/addViewportTag/AddViewportTagView';
import { decreaseOutboundLinkCountAudit } from '../recommendations/decreaseOutboundLinkCount/decreaseOutboundLinkCountAudit';
import { DecreaseOutboundLinkCountView } from '../recommendations/decreaseOutboundLinkCount/DecreaseOutboundLinkCountView';
import { makePageIndexableBySearchEnginesAudit } from '../recommendations/makePageIndexableBySearchEngines/makePageIndexableBySearchEnginesAudit';
import { MakePageIndexableBySearchEnginesView } from '../recommendations/makePageIndexableBySearchEngines/MakePageIndexableBySearchEnginesView';
import { increaseWordCountAudit } from '../recommendations/increaseWordCount/increaseWordCountAudit';
import { IncreaseWordCountView } from '../recommendations/increaseWordCount/IncreaseWordCountView';
import { FixH1TagsBlogView } from '../recommendations/addH1Tags/FixH1TagsBlogView';
import { FixH1TagsPagesView } from '../recommendations/addH1Tags/FixH1TagsPagesView';
import { grammarAudit } from '../recommendations/grammar/grammarAudit';
import { GrammarView } from '../recommendations/grammar/GrammarView';
import { filterUnauthorizedRecommendations } from '../../utils/auth';
import { useCorrectlySizedImagesAudit } from '../recommendations/useCorrectlySizedImages/useCorrectlySizedImagesAudit';
import { UseCorrectlySizedImagesView } from '../recommendations/useCorrectlySizedImages/UseCorrectlySizedImagesView';
import { addFormFollowUpEmailAudit } from '../recommendations/addFormFollowUpEmail/addFormFollowUpEmailAudit';
import { AddFormFollowUpEmailView } from '../recommendations/addFormFollowUpEmail/AddFormFollowUpEmailView';
import { UpgradeForFormAutomationView } from '../recommendations/upgradeForFormAutomation/UpgradeForFormAutomationView';
import { upgradeForFormAutomationAudit } from '../recommendations/upgradeForFormAutomation/upgradeForFormAutomationAudit';
const validateAuth = auth => {
  invariant(auth !== undefined, 'Auth was not passed into the config');
};
export const addFormFollowUpEmailRecommendation = {
  type: SeoRecommendations.ADD_FORM_FOLLOW_UP_EMAIL,
  gates: ['growth-monetization-654'],
  groupBy: SeoRecommendationGroups.FORMS,
  gathererKeys: [GathererKeys.FORMS],
  audit: addFormFollowUpEmailAudit,
  // @ts-expect-error TS can't tell if the editorApi is PageEditorApi
  view: AddFormFollowUpEmailView
};
export const upgradeForFormAutomationRecommendation = {
  type: SeoRecommendations.UPGRADE_FOR_FORM_AUTOMATION,
  permissionOverride: auth => {
    const {
      gates,
      user: {
        scopes
      }
    } = auth;
    const isUserUngated = gates.includes('growth-monetization-654');
    // NOTE: The simple form automation upgrade recommendation should only appear
    // if the portal has the free tier of Marketing Hub.
    //
    // Starter, professional, and enterprise tiers of Marketing Hub grant access to
    // this simple form automation feature. Therefore,this recommendation should
    // be hidden from portals with starter and above levels.
    const isMarketingFreeTier = scopes.includes('marketing-free-product') && !scopes.includes('marketing-starter-product') && !scopes.includes('hub-marketing-product');
    return isUserUngated && isMarketingFreeTier;
  },
  groupBy: SeoRecommendationGroups.FORMS,
  gathererKeys: [GathererKeys.FORMS],
  audit: upgradeForFormAutomationAudit,
  view: UpgradeForFormAutomationView
};
export const getSeoSectionConfig = (editorApi, auth) => {
  const fixH1TagsPages = {
    type: SeoRecommendations.FIX_H1_TAGS_PAGES,
    groupBy: SeoRecommendationGroups.HEADER,
    gathererKeys: [GathererKeys.H1_ELEMENTS, GathererKeys.ALL_MODULES],
    audit: addH1TagsAudit,
    // @ts-expect-error TS can't tell if the editorApi is PageEditorApi
    view: FixH1TagsPagesView
  };
  const fixH1TagsBlog = {
    type: SeoRecommendations.FIX_H1_TAGS_BLOG,
    groupBy: SeoRecommendationGroups.HEADER,
    gathererKeys: [GathererKeys.H1_ELEMENTS, GathererKeys.PAGE_TITLE, GathererKeys.POST_BODY],
    audit: addH1TagsAudit,
    // @ts-expect-error TS can't tell if the editorApi is BlogEditorApi
    view: FixH1TagsBlogView
  };
  const addMetaDescription = {
    type: SeoRecommendations.ADD_META_DESCRIPTION,
    groupBy: SeoRecommendationGroups.META_DESCRIPTION,
    gathererKeys: [GathererKeys.META_TAGS],
    audit: addMetaDescriptionAudit,
    view: AddMetaDescriptionView
  };
  const addTitle = {
    type: SeoRecommendations.ADD_TITLE,
    groupBy: SeoRecommendationGroups.TITLE,
    gathererKeys: [GathererKeys.PAGE_TITLE],
    audit: addTitleAudit,
    view: AddTitleView
  };
  const addViewportTag = {
    type: SeoRecommendations.ADD_VIEWPORT_TAG,
    groupBy: SeoRecommendationGroups.MOBILE_FRIENDLINESS,
    gathererKeys: [GathererKeys.META_TAGS],
    audit: addViewportTagAudit,
    view: AddViewportTagView
  };
  const decreaseOutboundLinkCount = {
    type: SeoRecommendations.DECREASE_OUTBOUND_LINK_COUNT,
    groupBy: SeoRecommendationGroups.LINKS,
    gathererKeys: [GathererKeys.LINKS],
    audit: decreaseOutboundLinkCountAudit,
    view: DecreaseOutboundLinkCountView
  };
  const grammar = {
    type: SeoRecommendations.GRAMMAR,
    groupBy: SeoRecommendationGroups.READABILITY,
    gathererKeys: [GathererKeys.READABILITY],
    gates: ['CI:Xray:ReadabilityRecsPhase1'],
    audit: grammarAudit,
    view: GrammarView
  };
  const increaseWordCount = {
    type: SeoRecommendations.INCREASE_WORD_COUNT,
    groupBy: SeoRecommendationGroups.CONTENT,
    gathererKeys: [GathererKeys.POST_BODY],
    audit: increaseWordCountAudit,
    view: IncreaseWordCountView
  };
  const makePageIndexableBySearchEngines = {
    type: SeoRecommendations.MAKE_PAGE_INDEXABLE_BY_SEARCH_ENGINES,
    groupBy: SeoRecommendationGroups.CRAWLING_AND_INDEXING,
    gathererKeys: [GathererKeys.META_TAGS],
    audit: makePageIndexableBySearchEnginesAudit,
    view: MakePageIndexableBySearchEnginesView
  };
  const removeDomainFromTitle = {
    type: SeoRecommendations.REMOVE_DOMAIN_FROM_TITLE,
    groupBy: SeoRecommendationGroups.TITLE,
    gathererKeys: [GathererKeys.PAGE_TITLE, GathererKeys.APEX_DOMAIN],
    audit: removeDomainFromTitleAudit,
    view: RemoveDomainFromTitleView
  };
  const removeTitleFromMetaDescription = {
    type: SeoRecommendations.REMOVE_TITLE_FROM_META_DESCRIPTION,
    groupBy: SeoRecommendationGroups.META_DESCRIPTION,
    gathererKeys: [GathererKeys.META_TAGS, GathererKeys.PAGE_TITLE],
    audit: removeTitleFromMetaDescriptionAudit,
    view: RemoveTitleFromMetaDescriptionView
  };
  const shortenMetaDescription = {
    type: SeoRecommendations.SHORTEN_META_DESCRIPTION,
    groupBy: SeoRecommendationGroups.META_DESCRIPTION,
    gathererKeys: [GathererKeys.META_TAGS],
    audit: shortenMetaDescriptionAudit,
    view: ShortenMetaDescriptionView
  };
  const shortenTitle = {
    type: SeoRecommendations.SHORTEN_TITLE,
    groupBy: SeoRecommendationGroups.TITLE,
    gathererKeys: [GathererKeys.PAGE_TITLE],
    audit: shortenTitleAudit,
    view: ShortenTitleView
  };
  const verifyEmptyImageAltText = {
    type: SeoRecommendations.VERIFY_EMPTY_IMAGE_ALT_TEXT,
    groupBy: SeoRecommendationGroups.IMAGES,
    gathererKeys: [GathererKeys.IMAGES],
    audit: verifyEmptyImageAltTextAudit,
    view: VerifyEmptyImageAltTextView
  };
  const addHyperlinkToPillarPage = {
    type: SeoRecommendations.ADD_HYPERLINK_TO_PILLAR_PAGE,
    groupBy: SeoRecommendationGroups.TOPICS,
    gathererKeys: [GathererKeys.LINKS]
  };
  const useCorrectlySizedImages = {
    type: SeoRecommendations.USE_CORRECTLY_SIZED_IMAGES,
    groupBy: SeoRecommendationGroups.IMAGES,
    gathererKeys: [GathererKeys.IMAGES, GathererKeys.ALL_MODULES, GathererKeys.MODULE_IDS_BY_BUILT_IN_TYPE],
    audit: useCorrectlySizedImagesAudit,
    // @ts-expect-error need to refactor config
    view: UseCorrectlySizedImagesView
  };
  const addTopicPhraseToMetaDescription = {
    type: SeoRecommendations.ADD_TOPIC_PHRASE_TO_META_DESCRIPTION,
    groupBy: SeoRecommendationGroups.TOPICS,
    gathererKeys: [GathererKeys.META_TAGS]
  };
  const addTopicPhraseToTitle = {
    type: SeoRecommendations.ADD_TOPIC_PHRASE_TO_TITLE,
    groupBy: SeoRecommendationGroups.TOPICS,
    gathererKeys: [GathererKeys.PAGE_TITLE]
  };
  const increaseClusterPhraseInBodyCount = {
    type: SeoRecommendations.INCREASE_CLUSTER_PHRASE_IN_BODY_COUNT,
    groupBy: SeoRecommendationGroups.TOPICS,
    gathererKeys: [GathererKeys.DOM_BODY, GathererKeys.POST_BODY]
  };
  const config = {
    recommendations: [addTopicPhraseToTitle, addHyperlinkToPillarPage, addTopicPhraseToMetaDescription, increaseClusterPhraseInBodyCount, grammar, fixH1TagsPages, fixH1TagsBlog, addTitle, addFormFollowUpEmailRecommendation, upgradeForFormAutomationRecommendation, removeDomainFromTitle, shortenTitle, addMetaDescription, shortenMetaDescription, removeTitleFromMetaDescription, verifyEmptyImageAltText, decreaseOutboundLinkCount, addViewportTag, makePageIndexableBySearchEngines, increaseWordCount, useCorrectlySizedImages],
    editorApi
  };
  const authorizedRecommendations = filterUnauthorizedRecommendations(config.recommendations, auth);
  return Object.assign({}, config, {
    recommendations: authorizedRecommendations
  });
};
export const getSeoSectionConfigForBlog = (editorApi, auth) => {
  validateAuth(auth);
  const unfilteredConfig = getSeoSectionConfig(editorApi, auth);
  const filteredRecommendations = unfilteredConfig.recommendations.filter(rec => rec.type !== SeoRecommendations.ADD_FORM_FOLLOW_UP_EMAIL && rec.type !== SeoRecommendations.FIX_H1_TAGS_PAGES && rec.type !== SeoRecommendations.UPGRADE_FOR_FORM_AUTOMATION && rec.type !== SeoRecommendations.USE_CORRECTLY_SIZED_IMAGES);
  return Object.assign({}, unfilteredConfig, {
    recommendations: filteredRecommendations
  });
};
export const getSeoSectionConfigForPages = (editorApi, auth) => {
  validateAuth(auth);
  const unfilteredConfig = getSeoSectionConfig(editorApi, auth);
  const filteredRecommendations = unfilteredConfig.recommendations.filter(rec => rec.type !== SeoRecommendations.FIX_H1_TAGS_BLOG && rec.type !== SeoRecommendations.INCREASE_WORD_COUNT && rec.type !== SeoRecommendations.GRAMMAR);
  return Object.assign({}, unfilteredConfig, {
    recommendations: filteredRecommendations
  });
};